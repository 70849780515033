<template>
    <div class="trustpilot_box ">
        <div class="trust_intro_flex">
            <div class="trust_title">
                <div class="trust_icon">
                    <img width="144" height="31" src="~/assets/assets/trustpilotlogo.svg" alt="trustpilot logo svg">
                </div>
         
                <p class="mb-0">Trustpilot lets customers review <br> businesses they’ve experienced</p>
            </div>
            <div class="trust_count">
                <div class="star_box mb-2">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="40" height="40" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                </div>
                <h3 class="mb-1">Excellent</h3>
                <a rel="nofollow noopener" target="_blank"
                    href="https://www.trustpilot.com/review/friendlydiamonds.com">300+ reviews</a>
            </div>
        </div>

        <!-- <div v-if="skeleton" class="trust_review_block">
            <div class="row for_desktop">
                <div class="col-md-3" v-for="index in 4" :key="index">
                    <div class="prod_block">
                        <b-skeleton class="mb-2" width="40%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="60%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="80%" height="20px"></b-skeleton>
                        <hr>
                        <b-skeleton class="mb-2" width="40%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="60%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="80%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="100%" height="20px"></b-skeleton>
                    </div>
                </div>
            </div>
            <div class="for_mobile">
                <div class="prod_block">
                    <div class="prod_image">
                        <b-skeleton class="mb-2" width="40%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="60%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="80%" height="20px"></b-skeleton>
                        <hr>
                        <b-skeleton class="mb-2" width="40%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="60%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="80%" height="20px"></b-skeleton>
                        <b-skeleton class="mb-2" width="100%" height="20px"></b-skeleton>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- remove v-else from below block -->
        <!-- :autoplay="{
                delay: 2500,
                disableOnInteraction: true,
            }" -->
        
        <!-- :loop="true"
            :loopAdditionalSlides="5" 
        -->
        <div class="trust_review_block">
            <swiper ref="c1" :modules="[SwiperAutoplay]"   
            :autoplay="{
                delay: 2000,
                disableOnInteraction: false,
            }" 
            :breakpoints="{
                '0': {
                    slidesPerView: 1.2,
                    slidesPerGroup: 1,
                },
                '512': {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                },
                '901': {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                },
                '1025': {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                }
            }" class="trustpilot__swiper">
                <swiper-slide v-for="item in trustpilot" :key="item.id" class="py-3 ">
                    <div class="slide_box h-100">
                        <TrustpilotReviewBox :data="item" />
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script setup>
// const skeleton = ref(true);

const trustpilot = [
    {
        id: 1,
        name: 'Adam M',
        date: 'February 01, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Amazing service from start to finish',
        description: 'Customer service and responsive was top tier. The final product exceeded my lofty expectations. I will definitely be using this company…'
    },
    {

        id: 2,
        name: 'Kristina B',
        date: 'January 24, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'The ring came exactly as expected',
        description: 'The ring came exactly as expected. Its stunning. Beautiful quality. Will definitely recommend and use this site again.'
    },
    {

        id: 3,
        name: 'Jeremy Slusher',
        date: 'January 20, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Perfect…',
        description: 'You made this experience absolutely perfect, I can’t find any other words to express how amazing this is...'

    },
    {
        id: 4,
        name: 'Steve Donahue',
        date: 'January 18, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'The customer service was excellent and…',
        description: 'The customer service was excellent and the ring quality was outstanding. We will definitely order from Friendly Diamonds again!'
    },
    {
        id: 5,
        name: 'Nate Danner',
        date: 'January 13, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Couldn`t be more pleased !',
        description: 'So far i`ve ordered 2 bands, and an engagement ring from FD, couldn`t be more pleased. my wife loves all of her rings, and FD is always so fast…'
    },
    {

        id: 6,
        name: 'Mel B',
        date: 'January 01, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'This is hands down the best online…',
        description: 'This is hands down the best online jewelry company. Customer service and buying experience was awesome. They were helpful…'

    },
    {
        id: 7,
        name: 'Helen H',
        date: 'December, 23, 2023',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'I thought the website was very easy…',
        description: 'I thought the website was very easy to navigate, making the purchase fun and personal. I felt very safe to make such a big purchase…'

    },
    {
        id: 8,
        name: 'John Heaton',
        date: 'July, 25, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Sparkling investment',
        description: 'The stone itself is beautiful and the setting seems professional and simplistic, Which is all you really need for a well made diamond ring....'

    }
]

</script>

<style scoped>
:global(.trustpilot__swiper .swiper-slide) {
    height: auto !important;
}

.trust_intro_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trust_title {
    border-right: 1px solid #eaeaea;
    position: relative;
}

.trust_count,
.trust_title {
    padding: 0 40px;
}

.trust_title .trust_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.trust_title h2 span {
    display: inline-block;
    margin: 10px 0 0 5px;
    font-weight: 600;
    color: #191919;
}

.trust_title p {
    color: #353535;
    font-size: 16px;
    line-height: normal;
}

.star_box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.trust_count h3 {
    font-size: 24px;
    color: #212529;
    text-align: center;
    font-weight: 600;
}

.trust_count a {
    display: inline-block;
    color: #353535;
    text-decoration: underline;
}

.trust_count {
    text-align: center;
}

.trust_review_block {
    margin-top: 4%;
}

.slide_box {
    padding: 2px;
}

@media(max-width: 600px) {
    .trust_review_block {
        margin-top: 10%;
    }

    .trust_title h2 {
        justify-content: center;
    }

    
    .trust_title p {
        text-align: center;
        font-size: 12px;
    }

  
    .trust_count h3 {
        font-size: 18px;
    }


    .trust_title {
        padding: 0 10px 0 0;
    }
 
    .trust_count a{
        font-size: 12px;
    }
    .trust_count {
        margin-left: 10px;
    }
    .trust_title p br {
        display: none;
    }
}
</style>